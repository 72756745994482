import React, { useState } from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';
import './Collapse.css';


export function Collapse({ children, label = "View", className = "" }: { children: React.ReactNode, label?: string, className?: string }) {
  const [open, setOpen] = useState<boolean>(false);

  function toggle() {
    setOpen(!open);
  }

  const cl = {
    'collapse': 1,
    [className]: className,
  };

  return (
    <div className={classNames(cl)}>
      <div onClick={toggle} className="collapse-toggle">
        <Icon name={open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} className="collapse-icon" />
        {label}
      </div>
      {open && (
        <div className="collapse-content">
          {children}
        </div>
      )}
    </div>
  );
}
