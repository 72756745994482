import { MenuConfigType } from '../types/Config';
import { ProgramItemType, ProgramContentType, ProgramTaskType } from '../types/Program';

export const example_program_items : ProgramItemType[] = [
  { id: 101, order: 1, parent_id: null, title: 'About' },
  { id: 1002, order: 1, parent_id: 101, title: 'About' },

  
  { id: 1, order: 1, parent_id: null, title: 'Programming basics' },
  { id: 100, order: 1, parent_id: 1, title: 'Introduction' },
  { id: 101, order: 1, parent_id: 1, title: 'VSCode editor' },
  { id: 102, order: 1, parent_id: 1, title: 'Variables and operators' },
  { id: 103, order: 1, parent_id: 1, title: 'Data types' },
  { id: 104, order: 1, parent_id: 1, title: 'Conditions' },
  { id: 105, order: 1, parent_id: 1, title: 'Functions' },
  { id: 107, order: 1, parent_id: 1, title: 'Data types - objects' },
  { id: 106, order: 1, parent_id: 1, title: 'Data types - strings' },
  { id: 109, order: 1, parent_id: 1, title: 'Loops' },
  { id: 108, order: 1, parent_id: 1, title: 'Data types - arrays' },
  { id: 110, order: 1, parent_id: 1, title: 'Methods in JavaScript' },

  { id: 2, order: 1, parent_id: null, title: 'Front-end introduction' },
  { id: 200, order: 1, parent_id: 2, title: 'Introduction' },
  { id: 202, order: 1, parent_id: 2, title: 'HTML first page' },
  { id: 203, order: 1, parent_id: 2, title: 'HTML tags' },
  { id: 204, order: 1, parent_id: 2, title: 'CSS introduction' },
  { id: 205, order: 1, parent_id: 2, title: 'Developer tools in browser' },
  { id: 206, order: 1, parent_id: 2, title: 'Fonts and icons' },
  { id: 207, order: 1, parent_id: 2, title: 'HTML/CSS tips' },

  { id: 210, order: 1, parent_id: 2, title: 'Git and GitHub' },
  { id: 211, order: 1, parent_id: 2, title: '(Optional) Figma' },
  // { id: 212, order: 1, parent_id: 2, title: '(Optional) Project #1' },

  { id: 221, order: 1, parent_id: 2, title: 'JavaScript introduction' },
  { id: 222, order: 1, parent_id: 2, title: 'JavaScript DOM manipulations' },
  { id: 223, order: 1, parent_id: 2, title: 'JavaScript knowledge check' },
  { id: 224, order: 1, parent_id: 2, title: 'Projects' },
  // { id: 231, order: 1, parent_id: 2, title: '(Optional) HTML table' },
  // { id: 232, order: 1, parent_id: 2, title: '(Optional) CSS effects - simple animation and other' },
  // { id: 233, order: 1, parent_id: 2, title: '(Optional) Webpage on mobile devices' },
  // { id: 241, order: 1, parent_id: 2, title: 'Project #2' },

  { id: 3, order: 1, parent_id: null, title: 'Advanced JavaScript and React' },
  { id: 300, order: 1, parent_id: 3, title: 'Introduction' },
  { id: 301, order: 1, parent_id: 3, title: 'Advanced JavaScript' },
  { id: 302, order: 1, parent_id: 3, title: 'React.js introduction' },
  { id: 304, order: 1, parent_id: 3, title: 'React components and props' },
  { id: 305, order: 1, parent_id: 3, title: 'Rendering tips' },
  { id: 307, order: 1, parent_id: 3, title: 'Component state' },
  { id: 308, order: 1, parent_id: 3, title: 'React hooks - useState' },
  { id: 309, order: 1, parent_id: 3, title: 'React hooks - useEffect' },
  { id: 315, order: 1, parent_id: 3, title: 'Forms' },
  { id: 316, order: 1, parent_id: 3, title: 'Web APIs - Fetch, Map' },
  { id: 320, order: 1, parent_id: 3, title: '(Optional) TypeScript' },
  { id: 317, order: 1, parent_id: 3, title: 'Projects' },
  

  { id: 4, order: 1, parent_id: null, title: 'Back-end introduction' },
  { id: 401, order: 1, parent_id: 4, title: 'Introduction' },
  { id: 403, order: 1, parent_id: 4, title: 'Python basics' },
  { id: 404, order: 1, parent_id: 4, title: 'Web server' },
  { id: 405, order: 1, parent_id: 4, title: 'First Python API' },
  { id: 406, order: 1, parent_id: 4, title: 'Database introduction - MySQL' },
  { id: 408, order: 1, parent_id: 4, title: 'SQL basics' },
  { id: 409, order: 1, parent_id: 4, title: 'Database setup' },
  { id: 411, order: 1, parent_id: 4, title: 'Manipulating data in API' },
  { id: 412, order: 1, parent_id: 4, title: 'HTTP requests' },
  { id: 413, order: 1, parent_id: 4, title: 'Projects' },
];

export const example_program_content : ProgramContentType[] = [
  { item_id: 101, title: '', item_prefix: '', content: `
    "Lorem 11 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
    `},
  { item_id: 102, title: '', item_prefix: '', content: ` 
    Lorem 12 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
    `},
  { item_id: 103, title: '', item_prefix: '', content: `
    "Lorem 13 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
    `},
  { item_id: 104, title: '', item_prefix: '', content: `
    "Lorem 14 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.  
  `},
  { item_id: 105, title: '', item_prefix: '', content: `
    "Lorem 15 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.  
  `},

];


export const program_item_empty : ProgramItemType = {
  id: 1, order: 1, parent_id: null, title: 'Section not found'
};

export const program_content_empty : ProgramContentType = {
  item_id: 1,
  item_prefix: '1.1.',
  content: 'Section not found',
  title: ''
}

export const example_menu_config: MenuConfigType[] = [
  // { id: 207, secondary: true, hidden: false, completed: false },
  // { id: 208, secondary: true, hidden: false, completed: false },
  // { id: 209, secondary: true, hidden: false, completed: false },
];